import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import { load } from 'recaptcha-v3';

@Component({
  watch: {
    $route() {
      (this.$root as any).$emit('bv::hide::modal', 'login-page');
    },
  },
})
export default class LoginForm extends Vue {
  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;

  public doLogin(): void {
    var th = this;
    var siteKey = '6LciUFMlAAAAAFE43IEXqi9b_afx8pVi9pCEzTjr';
    load(siteKey, { autoHideBadge: true }).then(recaptcha => {
      recaptcha.execute('login').then(token => {
        const data = { username: th.login, password: th.password, rememberMe: th.rememberMe, captcha: token };
        axios
          .post('api/authenticate', data)
          .then(result => {
            const bearerToken = result.headers.authorization;
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
              const jwt = bearerToken.slice(7, bearerToken.length);
              if (th.rememberMe) {
                localStorage.setItem('jhi-authenticationToken', jwt);
                sessionStorage.removeItem('jhi-authenticationToken');
              } else {
                sessionStorage.setItem('jhi-authenticationToken', jwt);
                localStorage.removeItem('jhi-authenticationToken');
              }
            }
            th.authenticationError = false;
            (th.$root as any).$emit('bv::hide::modal', 'login-page');
            th.accountService().retrieveAccount();
          })
          .catch(() => {
            th.authenticationError = true;
          });
      });
    });
  }
}
