import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Firmware = () => import('@/entities/firmware/firmware.vue');
// prettier-ignore
const FirmwareUpdate = () => import('@/entities/firmware/firmware-update.vue');
// prettier-ignore
const FirmwareDetails = () => import('@/entities/firmware/firmware-details.vue');
// prettier-ignore
const Client = () => import('@/entities/client/client.vue');
// prettier-ignore
const ClientUpdate = () => import('@/entities/client/client-update.vue');
// prettier-ignore
const ClientDetails = () => import('@/entities/client/client-details.vue');
// prettier-ignore
const WavelengthCoefficients = () => import('@/entities/wavelength-coefficients/wavelength-coefficients.vue');
// prettier-ignore
const WavelengthCoefficientsUpdate = () => import('@/entities/wavelength-coefficients/wavelength-coefficients-update.vue');
// prettier-ignore
const WavelengthCoefficientsDetails = () => import('@/entities/wavelength-coefficients/wavelength-coefficients-details.vue');
// prettier-ignore
const Device = () => import('@/entities/device/device.vue');
// prettier-ignore
const DeviceUpdate = () => import('@/entities/device/device-update.vue');
// prettier-ignore
const DeviceDetails = () => import('@/entities/device/device-details.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const Parameter = () => import('@/entities/parameter/parameter.vue');
// prettier-ignore
const ParameterUpdate = () => import('@/entities/parameter/parameter-update.vue');
// prettier-ignore
const ParameterDetails = () => import('@/entities/parameter/parameter-details.vue');
// prettier-ignore
const Profile = () => import('@/entities/profile/profile.vue');
// prettier-ignore
const ProfileUpdate = () => import('@/entities/profile/profile-update.vue');
// prettier-ignore
const ProfileDetails = () => import('@/entities/profile/profile-details.vue');
// prettier-ignore
const Translation = () => import('@/entities/translation/translation.vue');
// prettier-ignore
const TranslationUpdate = () => import('@/entities/translation/translation-update.vue');
// prettier-ignore
const TranslationDetails = () => import('@/entities/translation/translation-details.vue');
// prettier-ignore
const ApplicationModel = () => import('@/entities/application-model/application-model.vue');
// prettier-ignore
const ApplicationModelUpdate = () => import('@/entities/application-model/application-model-update.vue');
// prettier-ignore
const ApplicationModelDetails = () => import('@/entities/application-model/application-model-details.vue');
// prettier-ignore
const License = () => import('@/entities/license/license.vue');
// prettier-ignore
const LicenseUpdate = () => import('@/entities/license/license-update.vue');
// prettier-ignore
const LicenseDetails = () => import('@/entities/license/license-details.vue');
// prettier-ignore
const Correction = () => import('@/entities/correction/correction.vue');
// prettier-ignore
const CorrectionUpdate = () => import('@/entities/correction/correction-update.vue');
// prettier-ignore
const CorrectionDetails = () => import('@/entities/correction/correction-details.vue');
// prettier-ignore
const Accuracy = () => import('@/entities/accuracy/accuracy.vue');
// prettier-ignore
const AccuracyUpdate = () => import('@/entities/accuracy/accuracy-update.vue');
// prettier-ignore
const AccuracyDetails = () => import('@/entities/accuracy/accuracy-details.vue');
// prettier-ignore
const WarningLevels = () => import('@/entities/warning-levels/warning-levels.vue');
// prettier-ignore
const WarningLevelsUpdate = () => import('@/entities/warning-levels/warning-levels-update.vue');
// prettier-ignore
const WarningLevelsDetails = () => import('@/entities/warning-levels/warning-levels-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'firmware',
      name: 'Firmware',
      component: Firmware,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'firmware/new',
      name: 'FirmwareCreate',
      component: FirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'firmware/:firmwareId/edit',
      name: 'FirmwareEdit',
      component: FirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'firmware/:firmwareId/view',
      name: 'FirmwareView',
      component: FirmwareDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client',
      name: 'Client',
      component: Client,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client/new',
      name: 'ClientCreate',
      component: ClientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client/:clientId/edit',
      name: 'ClientEdit',
      component: ClientUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client/:clientId/view',
      name: 'ClientView',
      component: ClientDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'wavelength-coefficients',
      name: 'WavelengthCoefficients',
      component: WavelengthCoefficients,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'wavelength-coefficients/new',
      name: 'WavelengthCoefficientsCreate',
      component: WavelengthCoefficientsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'wavelength-coefficients/:wavelengthCoefficientsId/edit',
      name: 'WavelengthCoefficientsEdit',
      component: WavelengthCoefficientsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'wavelength-coefficients/:wavelengthCoefficientsId/view',
      name: 'WavelengthCoefficientsView',
      component: WavelengthCoefficientsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device',
      name: 'Device',
      component: Device,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/new',
      name: 'DeviceCreate',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/edit',
      name: 'DeviceEdit',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/view',
      name: 'DeviceView',
      component: DeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product',
      name: 'Product',
      component: Product,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'parameter',
      name: 'Parameter',
      component: Parameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'parameter/new',
      name: 'ParameterCreate',
      component: ParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'parameter/:parameterId/edit',
      name: 'ParameterEdit',
      component: ParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'parameter/:parameterId/view',
      name: 'ParameterView',
      component: ParameterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profile',
      name: 'Profile',
      component: Profile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profile/new',
      name: 'ProfileCreate',
      component: ProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profile/:profileId/edit',
      name: 'ProfileEdit',
      component: ProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profile/:profileId/view',
      name: 'ProfileView',
      component: ProfileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'translation',
      name: 'Translation',
      component: Translation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'translation/new',
      name: 'TranslationCreate',
      component: TranslationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'translation/:translationId/edit',
      name: 'TranslationEdit',
      component: TranslationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'translation/:translationId/view',
      name: 'TranslationView',
      component: TranslationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-model',
      name: 'ApplicationModel',
      component: ApplicationModel,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-model/new',
      name: 'ApplicationModelCreate',
      component: ApplicationModelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-model/:applicationModelId/edit',
      name: 'ApplicationModelEdit',
      component: ApplicationModelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-model/:applicationModelId/view',
      name: 'ApplicationModelView',
      component: ApplicationModelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license',
      name: 'License',
      component: License,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/new',
      name: 'LicenseCreate',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/edit',
      name: 'LicenseEdit',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/view',
      name: 'LicenseView',
      component: LicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'correction',
      name: 'Correction',
      component: Correction,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'correction/new',
      name: 'CorrectionCreate',
      component: CorrectionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'correction/:correctionId/edit',
      name: 'CorrectionEdit',
      component: CorrectionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'correction/:correctionId/view',
      name: 'CorrectionView',
      component: CorrectionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'accuracy',
      name: 'Accuracy',
      component: Accuracy,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'accuracy/new',
      name: 'AccuracyCreate',
      component: AccuracyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'accuracy/:accuracyId/edit',
      name: 'AccuracyEdit',
      component: AccuracyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'accuracy/:accuracyId/view',
      name: 'AccuracyView',
      component: AccuracyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'warning-levels',
      name: 'WarningLevels',
      component: WarningLevels,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'warning-levels/new',
      name: 'WarningLevelsCreate',
      component: WarningLevelsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'warning-levels/:warningLevelsId/edit',
      name: 'WarningLevelsEdit',
      component: WarningLevelsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'warning-levels/:warningLevelsId/view',
      name: 'WarningLevelsView',
      component: WarningLevelsDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
